/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Atom } from './Atom';
import {
    AtomFromJSON,
    AtomFromJSONTyped,
    AtomToJSON,
} from './Atom';

/**
 * A group is rule that defines a set of entities. A rule may be any combinations of formulas or atoms.
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * The unique identifier for the group
     * @type {string}
     * @memberof Group
     */
    id: string;
    /**
     * When the group was created
     * @type {string}
     * @memberof Group
     */
    createdAt: string;
    /**
     * The last time the group was updated
     * @type {string}
     * @memberof Group
     */
    updatedAt: string;
    /**
     * The unique identifier of the entity that owns the group
     * @type {string}
     * @memberof Group
     */
    owner: string;
    /**
     * the name of the group
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     * The group description, e.g. a summary of the rule that defines the group
     * @type {string}
     * @memberof Group
     */
    description: string;
    /**
     * A Rule as added to response objects (always displayed on response, even when empty)
     * @type {Array<Atom>}
     * @memberof Group
     */
    rule: Array<Atom>;
}

/**
 * Check if a given object implements the Group interface.
 */
export function instanceOfGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "rule" in value;

    return isInstance;
}

export function GroupFromJSON(json: any): Group {
    return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Group {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'owner': json['owner'],
        'name': json['name'],
        'description': json['description'],
        'rule': ((json['rule'] as Array<any>).map(AtomFromJSON)),
    };
}

export function GroupToJSON(value?: Group | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'owner': value.owner,
        'name': value.name,
        'description': value.description,
        'rule': ((value.rule as Array<any>).map(AtomToJSON)),
    };
}

